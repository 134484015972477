let MimeType;

(function (MimeType) {
  MimeType["Csv"] = "text/csv";
  MimeType["Gif"] = "image/gif";
  MimeType["Glb"] = "model/gltf-binary";
  MimeType["Hls"] = "application/x-mpegURL";
  MimeType["Html"] = "text/html";
  MimeType["Jpeg"] = "image/jpeg";
  MimeType["Js"] = "text/javascript";
  MimeType["Json"] = "application/json";
  MimeType["Mov"] = "video/quicktime";
  MimeType["Mp4"] = "video/mp4";
  MimeType["Webm"] = "video/webm";
  MimeType["Pdf"] = "application/pdf";
  MimeType["Png"] = "image/png";
  MimeType["Text"] = "text/plain";
  MimeType["Usdz"] = "model/vnd.usdz+zip";
  MimeType["Zip"] = "application/zip";
  MimeType["Webp"] = "image/webp";
  MimeType["Heic"] = "image/heic";
  MimeType["Svg"] = "image/svg+xml";
})(MimeType || (MimeType = {}));

export { MimeType };
